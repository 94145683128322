<template>
    <div class="az-signin-wrapper">
        <div class="az-card-signin">
            <img alt="" class="az-card-signin-logo" src="../../assets/logo-orange-full.png">
            <div class="az-signin-header">
                <p style="margin-bottom:25px; font-weight: bold">A password reset link will be sent to your registered email address</p>
                <div>
                    <div class="form-group">
                        <label for="email">Registered Email address</label>
                        <input :class="{'is-invalid': submitted && errors.has('email')}"
                               class="form-control rounded" data-vv-as="Email" id="email" name="email" placeholder="Enter your registered email"
                               type="email" v-model="email" v-validate="'required|email'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('email')">{{ errors.first('email') }}</div>
                    </div><!-- form-group -->
                    <button @click="forgotPassword" class="btn btn-az-secondary btn-block rounded">Submit</button>
                </div>
            </div><!-- az-signin-header -->
            <div class="az-signin-footer">
                <p>Back to login?
                    <router-link :to="{name: 'login'}">Click here</router-link>
                </p>
            </div><!-- az-signin-footer -->
        </div>
    </div><!-- az-signin-wrapper -->
</template>

<script>
    import myaxios from "axios";

    export default {
        name: "ForgotPasswordMessage",
        data(){
            return {
                email: null,
                submitted: false,
            }
        },
        methods: {
            async forgotPassword(){
                this.submitted = true;
                let valid = await this.$validator.validate();
                if (valid) {
                    try {
                        await myaxios.post(process.env.VUE_APP_ACCOUNTS_URL + `/accounts/users/forgot_password`, {
                            email: this.email
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                            }
                        });
                        this.$toastr.s("We successfully sent a password reset link to your registered email address", 'Success');
                        await this.$router.push('/auth/login');
                    }catch (e) {

                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>